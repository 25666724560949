
import { onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/ConsumerOrderRecordDispatchesEnums.ts";
import { Actions as LotActions } from "@/store/enums/LotEnums.ts";
import { Actions as ShipmentCarrierActions } from "@/store/enums/ShipmentCarriersEnums.ts";
import AddRecordDispatch from '@/views/orders/consumer/record_dispatches/AddRecordDispatch.vue';
import EditRecordDispatch from '@/views/orders/consumer/record_dispatches/EditRecordDispatch.vue';
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import moment from 'moment'
export default {
  name : 'ConsumerOrderRecordDispatches',
  components : {
    Table,
    AddRecordDispatch,
    EditRecordDispatch,
    DeleteRecord
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    const getAvailableCompositeLots = () => {
      store.dispatch(LotActions.GET_COMPOSITE_LOTS_ON_STOCK,{order_id : route.params.id});
      store.dispatch(ShipmentCarrierActions.GET_CARRIERS,);
    }

    onMounted(() => {
      getAvailableCompositeLots();

      setCurrentPageBreadcrumbs("Consumer Orders",[
        {title : "Consumer Order",to : "/orders/consumer/edit/"+route.params.id},
        {title : "Record Dispatches", to : "/orders/consumer/recorddispatches/"+route.params.id}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })

    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : true},
      {name : 'name', title : 'Product Name', sortable : true, sort : 'desc', searchable : true},
      {name : 'total', title : 'Total Ordered',sortable : true, sort : null, searchable : false},
      {name : 'dispatched', title : 'Total Dispatched',sortable : false, sort : null, searchable : false},
      {name : 'actions', title : 'Record Dispatch',sortable : false, sort : null, searchable : false}
    ]

    const columnsDispatched = [
      {name : 'reference', title : 'Reference', sortable : true, sort : 'desc', searchable : true},
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : true},
      {name : 'name', title : 'Product Name', sortable : true, sort : null, searchable : true},
      {name : 'deliveryNote', title : 'Dispatch Note#', sortable : true, sort : null, searchable : true},
      {name : 'deliveryDate', title : 'Dispatch Date',sortable : true, sort : null, searchable : true},
      {name : 'kitId', title : 'Kit ID',sortable : false, sort : null, searchable : true},
      {name : 'lotNumber', title : 'LOT Number',sortable : false, sort : null, searchable : true},
      {name : 'units', title : 'Units',sortable : false, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ]


    return {
      store,
      route,
      actions,
      columns,
      columnsDispatched,
      moment,
      getAvailableCompositeLots
    }
  }
}
