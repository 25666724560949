
import { Actions } from "@/store/enums/ConsumerOrderRecordDispatchesEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted, getCurrentInstance, computed } from 'vue'
 export default {
   name : 'EditRecordDispatch',
   props : {
     dispatchRecord : {
       type : [Array,Object]
     },
   },
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const id = getCurrentInstance() as any;

     const myDispatchRecord = reactive(JSON.parse(JSON.stringify({ value : props.dispatchRecord})));

     const success = ref(false);

     const errors = reactive({
       value : {}
     });


     onMounted(() => {
       const myModalEl = document.getElementById('exampleModal'+id.uid) as HTMLElement;

       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         myDispatchRecord.value = JSON.parse(JSON.stringify(props.dispatchRecord))
       })

     })


     const submitForm = () => {

       const submitButton = ref<HTMLElement | null>(document.getElementById('submitButton'+id.uid) as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.UPDATE_CONSUMER_ORDER_PRODUCT_DISPATCH,myDispatchRecord.value).then(() => {

         errors.value = [];
         success.value = true;

         setTimeout(function(){
           submitButton.value?.removeAttribute("data-kt-indicator");
           const closeModal = document.getElementById('close-modal'+id.uid) as HTMLElement;
           closeModal.click();
           emit('input')
         },1000)

       }).catch((response) => {
         submitButton.value?.removeAttribute("data-kt-indicator");
         errors.value = response.data.errors;
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

       })
     }

     const compositeLotsOnStock = computed(() => {

       const lots : string[] = [];

       store.getters.mycompositeLotsOnStock.forEach((item) => {

         if(Number(myDispatchRecord.value.product_id) == Number(item.product_id)){

           if(Number(myDispatchRecord.value.lot_id) == Number(item.lot_id)){

             lots.push(item)

           }else{

             if(Number(item.inCustomerStock) > 0){

               lots.push(item)

             }

           }

         }

       });
       return lots;
     })

     return {
       id,
       props,
       errors,
       store,
       submitForm,
       myDispatchRecord,
       success,
       compositeLotsOnStock
     }
   }
 }
