<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-sm btn-success font-weight-bold" data-bs-toggle="modal" :data-bs-target="'#exampleModal'+compId.uid">
    <i class="fs-1-3 la la-shipping-fast"></i>
  </button>

  <div class="modal fade" :id="'exampleModal'+compId.uid" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Record Dispatch 1 / 2</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <label class="col-form-label required fw-bold fs-6">Kit ID</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.kitId}" v-model="formData.kitId" @input="searchTimeOut()">
              <span class="text-danger" v-if="errors.value && errors.value.kitId">{{errors.value && errors.value.kitId[0]}}</span>
            </div>
            <div class="col-6">

              <label class="col-form-label fw-bold fs-6">Print</label>
              <div>

                <button class="btn btn-secondary" @click="generateLabRequest" :disabled="!kitValid"><i class="fs-1-3 la la-print"></i>Lab Request Form</button>

                <button class="btn btn-secondary ms-3" @click="generateReferenceCard" :disabled="!kitValid"><i class="fs-1-3 la la-print"></i>Reference Card</button>

              </div>

            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id="'close-modal'+compId.uid">Close</button>
          <button
            type="submit"
            :id="'addSubmitBtn'+compId.uid"
            class="btn btn-primary fw-bolder"
            @click="openMainWindow"
            :disabled="!kitValid"
          >
            <span class="indicator-label">
              Next
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>


<!--=============================================================================================================-->

  <button type="button" class="btn btn-sm btn-success font-weight-bold d-none" id="mainModalBtn" data-bs-toggle="modal" :data-bs-target="'#exampleModal1'+compId.uid">
    <i class="fs-1-3 la la-shipping-fast"></i>
  </button>

  <div class="modal fade" :id="'exampleModal1'+compId.uid" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModal1Label">Add Record Dispatch</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully added dispatch record!
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">Reference</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.reference}" v-model="formData.reference">
              <span class="text-danger" v-if="errors.value && errors.value.reference">{{errors.value && errors.value.reference[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label fw-bold fs-6">Dispatch Note#</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.deliveryNote}" v-model="formData.deliveryNote">
              <span class="text-danger" v-if="errors.value && errors.value.deliveryNote">{{errors.value && errors.value.deliveryNote[0]}}</span>
            </div>
          </div>


          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">Dispatch Date</label>
              <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.deliveryDate}" v-model="formData.deliveryDate">
              <span class="text-danger" v-if="errors.value && errors.value.deliveryDate">{{errors.value && errors.value.deliveryDate[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">Kit ID</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.kitId}" v-model="formData.kitId">
              <span class="text-danger" v-if="errors.value && errors.value.kitId">{{errors.value && errors.value.kitId[0]}}</span>
            </div>
          </div>


          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">LOT</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.lot_id}" v-model="formData.lot_id">
                <option selected disabled value="null">Select Lot</option>
                <option :value="item.lot_id" v-for="(item,key) in compositeLotsOnStock" :key="key">{{item.code}} / {{item.lotNumber}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.lot_id">{{errors.value && errors.value.lot_id[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">Units</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.units}" v-model="formData.units">
              <span class="text-danger" v-if="errors.value && errors.value.units">{{errors.value && errors.value.units[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">Outbound Carrier</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.outbound_shipment_carrier_id}" v-model="formData.outbound_shipment_carrier_id">
                <option selected disabled value="null">Select Carrier</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myCarriers" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.outbound_shipment_carrier_id">{{errors.value && errors.value.outbound_shipment_carrier_id[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">Outbound Tracking Number</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.outboundNumber}" v-model="formData.outboundNumber" @input="formData.outboundNumber = $event.target.value.toUpperCase().replace(' ','')">
              <span class="text-danger" v-if="errors.value && errors.value.outboundNumber">{{errors.value && errors.value.outboundNumber[0]}}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">Return Carrier</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.return_shipment_carrier_id}" v-model="formData.return_shipment_carrier_id">
                <option selected disabled value="null">Select Carrier</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myCarriers" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.return_shipment_carrier_id">{{errors.value && errors.value.return_shipment_carrier_id[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">Return Tracking Number</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.returnNumber}" v-model="formData.returnNumber" @input="formData.returnNumber = $event.target.value.toUpperCase().replace(' ','')">
              <span class="text-danger" v-if="errors.value && errors.value.returnNumber">{{errors.value && errors.value.returnNumber[0]}}</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id="'close-modal1'+compId.uid">Close</button>
          <button
            type="submit"
            :id="'addSubmitBtn1'+compId.uid"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/ConsumerOrderRecordDispatchesEnums.ts";
import { useStore } from 'vuex';
import { ref, reactive, onMounted, getCurrentInstance, computed } from 'vue';
import { Modal } from "bootstrap";
import  onScan  from "onscan.js/onscan.min.js";
 export default {
   name : 'AddRecordDispatch',
   props : ["id", "product_id", "uoid"],
   emits: ['input'],
   setup(props, { emit }){
     console.log(props)
     const store = useStore();
     const compId = getCurrentInstance() as any;
     const success = ref(false)
     const kitValid = ref(false)
     const errors = reactive({
       value : {}
     });
     const formData = reactive({
       order_product_id : props.id,
       outbound_shipment_carrier_id : null,
       outboundNumber : '',
       return_shipment_carrier_id : null,
       returnNumber : '',
       reference : props.uoid,
       deliveryNote : '',
       deliveryDate : null,
       kitId : null,
       lot_id : null,
       units : null
     })
     onMounted(() => {

        if(!onScan.isAttachedTo(document)){

          // Enable scan events for the entire document
           onScan.attachTo(document);

           // Register event listener
           document.addEventListener('scan', (scanEvent: any) => {
             console.log(scanEvent)
             formData.kitId = scanEvent.detail.scanCode.trim()
           });

        }


       const myModalEl1 = document.getElementById('exampleModal1'+compId.uid) as any;
       myModalEl1.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.reference = '';
         formData.deliveryNote = '';
         formData.deliveryDate = null;
         formData.kitId = null;
         formData.lot_id = null;
         formData.units = null;
         formData.outbound_shipment_carrier_id = null;
         formData.outboundNumber  = '';
         formData.return_shipment_carrier_id = null;
         formData.returnNumber = '';
       })
     })
     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addSubmitBtn1'+compId.uid) as HTMLElement);
       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }
       store.dispatch(Actions.ADD_CONSUMER_ORDER_PRODUCT_DISPATCH,formData).then(() => {
         errors.value = [];
         success.value = true;
         setTimeout(function(){
           const closeModal = document.getElementById('close-modal1'+compId.uid) as HTMLElement;
           closeModal.click();
           emit('input');
           submitButton.value?.removeAttribute("data-kt-indicator");
         },1000)
       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)
         errors.value = response.data.errors;
         submitButton.value?.removeAttribute("data-kt-indicator");
       })
     }
     const compositeLotsOnStock = computed(() => {
       const lots : string[] = [];
       store.getters.mycompositeLotsOnStock.forEach((item) => {
         if(Number(item.inCustomerStock) > 0 && Number(item.product_id) == Number(props.product_id)){
           lots.push(item)
         }
       });
       return lots;
     })
     const checkKitId = () => {
       errors.value = [];
       store.dispatch(Actions.CHECK_KIT_ID,{kitId : formData.kitId}).then((response) => {
         console.log(response)
         if(response.success){
           kitValid.value = true;
         }
       }).catch((response) => {
         kitValid.value = false;
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)
         errors.value = response.data.errors;
       })
     }
     const timeout = ref();
     const searchTimeOut = () => {
          clearTimeout(timeout.value);
          // Make a new timeout set to go off in 800ms
          timeout.value = setTimeout(() => {
               checkKitId();
          }, 800);
    }
    const openMainWindow = () => {
      const closeModal = document.getElementById('close-modal'+compId.uid) as HTMLElement;
      closeModal.click();
      const mainModal = new Modal(document.getElementById('exampleModal1'+compId.uid));
      mainModal.show()
    }
    const generateLabRequest = () => {
      store.dispatch(Actions.GENERATE_LAB_REQUEST_FORM,{kitId : formData.kitId}).then((response) => {
        const blob = new Blob([response], { type: 'application/pdf', filename : 'labRequestForm.pdf' } as object)
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
    }
    const generateReferenceCard = () => {
      store.dispatch(Actions.GENERATE_REFERENCE_CARD,{kitId : formData.kitId}).then((response) => {
        const blob = new Blob([response], { type: 'application/pdf', filename : 'referenceCard.pdf' } as object)
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
    }
     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success,
       compId,
       compositeLotsOnStock,
       kitValid,
       searchTimeOut,
       openMainWindow,
       generateLabRequest,
       generateReferenceCard
     }
   }
 }
</script>
