
import { Actions } from "@/store/enums/ConsumerOrderRecordDispatchesEnums.ts";
import { useStore } from 'vuex';
import { ref, reactive, onMounted, getCurrentInstance, computed } from 'vue';
import { Modal } from "bootstrap";
import  onScan  from "onscan.js/onscan.min.js";
 export default {
   name : 'AddRecordDispatch',
   props : ["id", "product_id", "uoid"],
   emits: ['input'],
   setup(props, { emit }){
     console.log(props)
     const store = useStore();
     const compId = getCurrentInstance() as any;
     const success = ref(false)
     const kitValid = ref(false)
     const errors = reactive({
       value : {}
     });
     const formData = reactive({
       order_product_id : props.id,
       outbound_shipment_carrier_id : null,
       outboundNumber : '',
       return_shipment_carrier_id : null,
       returnNumber : '',
       reference : props.uoid,
       deliveryNote : '',
       deliveryDate : null,
       kitId : null,
       lot_id : null,
       units : null
     })
     onMounted(() => {

        if(!onScan.isAttachedTo(document)){

          // Enable scan events for the entire document
           onScan.attachTo(document);

           // Register event listener
           document.addEventListener('scan', (scanEvent: any) => {
             console.log(scanEvent)
             formData.kitId = scanEvent.detail.scanCode.trim()
           });

        }


       const myModalEl1 = document.getElementById('exampleModal1'+compId.uid) as any;
       myModalEl1.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.reference = '';
         formData.deliveryNote = '';
         formData.deliveryDate = null;
         formData.kitId = null;
         formData.lot_id = null;
         formData.units = null;
         formData.outbound_shipment_carrier_id = null;
         formData.outboundNumber  = '';
         formData.return_shipment_carrier_id = null;
         formData.returnNumber = '';
       })
     })
     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addSubmitBtn1'+compId.uid) as HTMLElement);
       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }
       store.dispatch(Actions.ADD_CONSUMER_ORDER_PRODUCT_DISPATCH,formData).then(() => {
         errors.value = [];
         success.value = true;
         setTimeout(function(){
           const closeModal = document.getElementById('close-modal1'+compId.uid) as HTMLElement;
           closeModal.click();
           emit('input');
           submitButton.value?.removeAttribute("data-kt-indicator");
         },1000)
       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)
         errors.value = response.data.errors;
         submitButton.value?.removeAttribute("data-kt-indicator");
       })
     }
     const compositeLotsOnStock = computed(() => {
       const lots : string[] = [];
       store.getters.mycompositeLotsOnStock.forEach((item) => {
         if(Number(item.inCustomerStock) > 0 && Number(item.product_id) == Number(props.product_id)){
           lots.push(item)
         }
       });
       return lots;
     })
     const checkKitId = () => {
       errors.value = [];
       store.dispatch(Actions.CHECK_KIT_ID,{kitId : formData.kitId}).then((response) => {
         console.log(response)
         if(response.success){
           kitValid.value = true;
         }
       }).catch((response) => {
         kitValid.value = false;
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)
         errors.value = response.data.errors;
       })
     }
     const timeout = ref();
     const searchTimeOut = () => {
          clearTimeout(timeout.value);
          // Make a new timeout set to go off in 800ms
          timeout.value = setTimeout(() => {
               checkKitId();
          }, 800);
    }
    const openMainWindow = () => {
      const closeModal = document.getElementById('close-modal'+compId.uid) as HTMLElement;
      closeModal.click();
      const mainModal = new Modal(document.getElementById('exampleModal1'+compId.uid));
      mainModal.show()
    }
    const generateLabRequest = () => {
      store.dispatch(Actions.GENERATE_LAB_REQUEST_FORM,{kitId : formData.kitId}).then((response) => {
        const blob = new Blob([response], { type: 'application/pdf', filename : 'labRequestForm.pdf' } as object)
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
    }
    const generateReferenceCard = () => {
      store.dispatch(Actions.GENERATE_REFERENCE_CARD,{kitId : formData.kitId}).then((response) => {
        const blob = new Blob([response], { type: 'application/pdf', filename : 'referenceCard.pdf' } as object)
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
    }
     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success,
       compId,
       compositeLotsOnStock,
       kitValid,
       searchTimeOut,
       openMainWindow,
       generateLabRequest,
       generateReferenceCard
     }
   }
 }
