<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-sm btn-light-warning font-weight-bold" data-bs-toggle="modal" :data-bs-target="'#exampleModal'+id.uid">
    <i class="fs-1-3 la la-edit"></i>
  </button>
  <!-- Modal -->
  <div class="modal fade" :id="'exampleModal'+id.uid" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Dispatch Record</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully changed dispatch record!
              </div>
            </div>
          </div>


          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Reference</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.reference}" v-model="myDispatchRecord.value.reference">
              <span class="text-danger" v-if="errors.value && errors.value.reference">{{errors.value && errors.value.reference[0]}}</span>
            </div>
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6">Dispatch Note#</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.deliveryNote}" v-model="myDispatchRecord.value.deliveryNote">
              <span class="text-danger" v-if="errors.value && errors.value.deliveryNote">{{errors.value && errors.value.deliveryNote[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">Dispatch Date</label>
              <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.deliveryDate}" v-model="myDispatchRecord.value.deliveryDate">
              <span class="text-danger" v-if="errors.value && errors.value.deliveryDate">{{errors.value && errors.value.deliveryDate[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">Kit ID</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.kitId}" v-model="myDispatchRecord.value.kitId">
              <span class="text-danger" v-if="errors.value && errors.value.kitId">{{errors.value && errors.value.kitId[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">LOT</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.lot_id}" v-model="myDispatchRecord.value.lot_id">
                <option selected disabled value="null">Select Lot</option>
                <option :value="item.lot_id" v-for="(item,key) in compositeLotsOnStock" :key="key">{{item.code}} / {{item.lotNumber}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.lot_id">{{errors.value && errors.value.lot_id[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">Units</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.units}" v-model="myDispatchRecord.value.units">
              <span class="text-danger" v-if="errors.value && errors.value.units">{{errors.value && errors.value.units[0]}}</span>
            </div>
          </div>


          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">Outbound Carrier</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.outbound_shipment_carrier_id}" v-model="myDispatchRecord.value.outbound_shipment_carrier_id">
                <option selected disabled value="null">Select Carrier</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myCarriers" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.outbound_shipment_carrier_id">{{errors.value && errors.value.outbound_shipment_carrier_id[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">Outbound Tracking Number</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.outboundNumber}" v-model="myDispatchRecord.value.outboundNumber" @input="myDispatchRecord.value.outboundNumber = $event.target.value.toUpperCase().replace(' ','')">
              <span class="text-danger" v-if="errors.value && errors.value.outboundNumber">{{errors.value && errors.value.outboundNumber[0]}}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">Return Carrier</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.return_shipment_carrier_id}" v-model="myDispatchRecord.value.return_shipment_carrier_id">
                <option selected disabled value="null">Select Carrier</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myCarriers" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.return_shipment_carrier_id">{{errors.value && errors.value.return_shipment_carrier_id[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">Return Tracking Number</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.returnNumber}" v-model="myDispatchRecord.value.returnNumber" @input="myDispatchRecord.value.returnNumber = $event.target.value.toUpperCase().replace(' ','')">
              <span class="text-danger" v-if="errors.value && errors.value.returnNumber">{{errors.value && errors.value.returnNumber[0]}}</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id="'close-modal'+id.uid">Close</button>
          <button
            type="submit"
            :id="'submitButton'+id.uid"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/ConsumerOrderRecordDispatchesEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted, getCurrentInstance, computed } from 'vue'
 export default {
   name : 'EditRecordDispatch',
   props : {
     dispatchRecord : {
       type : [Array,Object]
     },
   },
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const id = getCurrentInstance() as any;

     const myDispatchRecord = reactive(JSON.parse(JSON.stringify({ value : props.dispatchRecord})));

     const success = ref(false);

     const errors = reactive({
       value : {}
     });


     onMounted(() => {
       const myModalEl = document.getElementById('exampleModal'+id.uid) as HTMLElement;

       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         myDispatchRecord.value = JSON.parse(JSON.stringify(props.dispatchRecord))
       })

     })


     const submitForm = () => {

       const submitButton = ref<HTMLElement | null>(document.getElementById('submitButton'+id.uid) as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.UPDATE_CONSUMER_ORDER_PRODUCT_DISPATCH,myDispatchRecord.value).then(() => {

         errors.value = [];
         success.value = true;

         setTimeout(function(){
           submitButton.value?.removeAttribute("data-kt-indicator");
           const closeModal = document.getElementById('close-modal'+id.uid) as HTMLElement;
           closeModal.click();
           emit('input')
         },1000)

       }).catch((response) => {
         submitButton.value?.removeAttribute("data-kt-indicator");
         errors.value = response.data.errors;
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

       })
     }

     const compositeLotsOnStock = computed(() => {

       const lots : string[] = [];

       store.getters.mycompositeLotsOnStock.forEach((item) => {

         if(Number(myDispatchRecord.value.product_id) == Number(item.product_id)){

           if(Number(myDispatchRecord.value.lot_id) == Number(item.lot_id)){

             lots.push(item)

           }else{

             if(Number(item.inCustomerStock) > 0){

               lots.push(item)

             }

           }

         }

       });
       return lots;
     })

     return {
       id,
       props,
       errors,
       store,
       submitForm,
       myDispatchRecord,
       success,
       compositeLotsOnStock
     }
   }
 }
</script>
